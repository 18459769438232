import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import { TicketScreen } from "../screens/TicketScreen";
import ScrollToTop from "../components/ScrollToTop";

export const AppRouter = () => {

    // console.warn = function () { };
    // console.error = function () { };

    return (

        <Router>

            <div className='router-content'>

                <ScrollToTop />

                <Switch>

                    <Route
                        exact
                        path='/'
                        component={HomeScreen}
                    />
                    <Route
                        exact
                        path='/:ticket'
                        component={TicketScreen}
                    />


                    <Redirect to='/' />

                </Switch>

                {/* <Rights brand="Fiebre Fiesta" /> */}

            </div>

        </Router>

    )
}
